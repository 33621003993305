/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

th > div.sortable {
    position: relative;
    cursor: pointer;
    padding-right: 10px;
}

th > div.sortable::after {
    font-family: "Font Awesome 5 Free";
    content: "\f0dc";
    position: absolute;
    right: 8px;
    color: #999;
}

th > div.sortable.asc::after {
    content: "\f0d8";
}

th > div.sortable.desc::after {
    content: "\f0d7";
}

th > div.sortable:hover::after {
    color: #333;
}

.inner-btn {
    position: absolute;
    border: none;
    background-color: transparent;
    right: 0;
    line-height: 2em;
    z-index: 10;
}

textarea.wt-details {
    line-height: 2.5ex;
    height: 25ex;
}

.cone-icon {
    width: 1.2em;
    height: 1.2em;
    background-image: url("../public/cone_icon.svg");
    display: inline-block;
    background-size: 1.2em;
    vertical-align: text-bottom;
}

.multi-line-text {
    white-space: pre-wrap
}
